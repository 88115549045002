.field {
    width: 100%;
    height: 60px;
    border-radius: 4px;
    position: relative;
    background-color: rgba(143, 143, 143, 0.3);
    transition: 0.3s all;
  }
  .field:hover {
    background-color: rgba(255, 255, 255, 0.45);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  }

  .field.useSpace{
    margin-bottom: 6px;
  }

  .field.focused input {
    padding: 16px 16px 8px 16px;
  }
  .field.focused input + label {
    top: 4px;
    opacity: 1;
    color: #28cc70;
  }
  .field.locked {
   pointer-events: none;
  }
  .field input {
    width: 100%;
    height: 50px;
    position: relative;
    padding: 6px 16px 16px 16px;
    border: none;
    border-radius: 4px;
    font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    color: white;
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
    -webkit-appearance: none;
  }
  .field input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
  .field input::-moz-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
  .field input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
  .field input:-moz-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }

  .field input + label {
    position: absolute;
    top: 10px;
    left: 16px;
    font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    color: #ffffff;
    opacity: 0;
    pointer-events: none;
    transition: 0.1s all ease-in-out;
  }
  .field input + label.error {
   color: #ec392f;
  }