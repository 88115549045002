
.boxesContainer{
    display: flex;
    flex-direction: row; 
    width: 100%;
    justify-content: space-evenly;
}


.boxLayout{
display: flex; 
flex-direction: column;

}

.boxStyle{
    background-color: #1f2020;
    color: white;
    resize: none;
    height: 30vh;
    width: 30vw;
}

textarea::-webkit-scrollbar {
    width: 1em;
}
textarea::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

textarea::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

